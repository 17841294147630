import * as Accordion from '@radix-ui/react-accordion';
import { useState } from 'react';
import Paragraph from '@ui/components/Paragraph/Paragraph';
import { cn } from '@ngg/storefront-utils';
import ChevronSvg from '@ui/components/icon/assets/chevron.svg';

import type { INCOMING_ITEM } from '../Stock.types';
import { type StockStatusProps } from '../Stock.types';
import StockDialog from '../StockDialog';
import StockIndicator from './StockIndicator';

export default function IncomingItem({
  status,
  config,
}: {
  status: INCOMING_ITEM;
  config: StockStatusProps;
}) {
  const [popup, setPopup] = useState<boolean>(false);
  const { type, item, className, stockInfo, dictionary, ...props } = config;
  if (type === 'pdp') {
    return (
      <>
        <Accordion.Root type="multiple" className={cn('text-sm', className)}>
          <Accordion.Item value={item?.id ?? '1'} {...props}>
            <Accordion.Header asChild>
              <div className="inline-flex items-start gap-2">
                <StockIndicator status={status} />
                <span className="inline-block">
                  <strong className="font-bold">
                    {dictionary?.onTheWayToUs}
                    {', '}
                  </strong>
                  {dictionary?.backInStock?.replace('%X%', status.date)}
                </span>
                <Accordion.Trigger className="group inline-flex items-center gap-1 whitespace-nowrap underline">
                  {dictionary?.moreInfo}
                  <ChevronSvg className="h-3 w-3 transition-all group-data-[state=closed]:rotate-180 group-data-[state=open]:rotate-0" />
                </Accordion.Trigger>
              </div>
            </Accordion.Header>
            <Accordion.Content className="mt-2 overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
              <Paragraph className="text-gray-500">
                {dictionary?.incomingItemExplanation}{' '}
                {stockInfo && (
                  <button
                    className="text-black underline"
                    onClick={() => setPopup(true)}>
                    {dictionary?.readMore}
                  </button>
                )}
              </Paragraph>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
        {stockInfo && (
          <StockDialog
            open={popup}
            onClose={() => setPopup(false)}
            block={stockInfo}
          />
        )}
      </>
    );
  }
  if (type === 'checkout') {
    return (
      <div className={cn('inline-flex items-start gap-2 text-xs', className)}>
        <StockIndicator status={status} />
        <span className="inline-block">
          <strong className="font-bold">
            {dictionary?.onTheWayToUs},&nbsp;
          </strong>
          {dictionary?.backInStock?.replace('%X%', status.date)}
        </span>
      </div>
    );
  }
  return (
    <div
      className={cn('inline-flex items-start gap-2 text-xxs', className)}
      {...props}>
      <StockIndicator status={status} />
      <span className="inline-block font-bold">{dictionary?.onTheWayToUs}</span>
    </div>
  );
}
